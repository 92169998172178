import "./RandomQuote.css";

const hsData = [
{content: "Trading doesn't just reveal your character; it also builds it if you stay in the game long enough. -  Yvan Byeajee"  },
{content: "Confidence is not 'I will profit on this trade.' Confidence is 'I will be fine if I don't profit from this trade. -  Yvan Byeajee"  },
{content: "Trading is very competitive and you have to be able to handle getting your butt kicked. -  Paul Tudor Jones"  },
{content: "Hope is [a] bogus emotion that only costs you money. - Jim Cramer"  },
{content: "Never, ever argue with your trading system. - Michael Covel "  },
{content: "Do more of what works and less of what doesn't. -  Steve Clark"  },
{content: "Do not anticipate and move without market confirmation - being a little late in your trade is your insurance that you are right or wrong. -  Jesse Livermore"  },
{content: "Most traders take a good system and destroy it by trying to make it into a perfect system. -  Robert Prechter"  },
{content: "It was really later on when I decided that the reason there are so many technical indicators out there is because none of them work very well. -  Chuck Hayes"  },
{content: "Sheer will and determination is no substitute for something that actually works. -  Jason Klatt"  },
{content: "I get real, real concerned when I see trading strategies with too many rules (you should too). -  Larry Connors"  },
{content: "The goal of a successful trader is to make the best trades. Money is secondary. -  Alexander Elder"  },
{content: "If most traders would learn to sit on their hands 50 percent of the time, they would make a lot more money. -  Bill Lipschutz"  },
{content: "There is a time to go long, a time to go short and a time to go fishing. -  Jesse Livermore"  },  
{content: "I just wait until there is money lying in the corner, and all I have to do is go over there and pick it up. I do nothing in the meantime. -  Jim Rogers"  },
{content: "I believe in analysis and not forecasting. -  Nicolas Darvas"  },
{content: "Short-term volatility is greatest at turning points and diminishes as a trend becomes established. -  George Soros"  },
{content: "The core problem, however, is the need to fit markets into a style of trading rather than finding ways to trade that fit with market behaviour. -  Brett Steenbarger"  },
{content: "I always laugh at people who say, 'I've never met a rich technician.' I love that! It's such an arrogant, nonsensical response. I used fundamentals for nine years and got rich as a technician. -  Martin Schwartz"  },
{content: "An investment in knowledge pays the best interest. - Benjamin Franklin"  },
{content: "I know where I'm getting out before I get in. - Bruce Kovner"  },
{content: "What seems too high and risky to the majority generally goes higher and what seems low and cheap generally goes lower. -  William O'Neil"  },
{content: "Markets can remain irrational longer than you can remain solvent. - John Maynard Keynes"  },      
{content: "All you need is one pattern to make a living. -  Linda Raschke"  },
{content: "Patterns don't work 100% of the time. But they are still critical because they help you define your risk. If you ignore patterns and focus on hunches, feelings, and hot tips, just forget about achieving consistency. - Ifan Wei"  },
{content: "Novice Traders trade 5 to 10 times too big. They are taking 5 to 10 percent risk, on a trade they should be taking 1 to 2 percent risk on. -  Bruce Kovner"  },
{content: "Are you willing to lose money on a trade? If not, then don't take it. You can only win if you're not afraid to lose. And you can only do that if you truly accept the risks in front of you. -  Sami Abusaad"  },
{content: "You never know what kind of setup [the] market will present to you, your objective should be to find [an] opportunity where risk-reward ratio is best. - Jaymin Shah"  },
{content: "In trading/investing, it's not about how much you make but rather how much you don't lose. -  Bernard Baruch"  },
{content: "Do more of what works and less of what doesn't. -  Steve Clark"  },
{content: "I have two basic rules about winning in trading as well as in life: 1. If you don't bet, you can't win. 2. If you lose all your chips, you can't bet. -  Larry Hite"  },
{content: "If you personalise losses, you can't trade. -  Bruce Kovner"  },
{content: "The elements of good trading are (1) cutting losses, (2) cutting losses, and (3) cutting losses. If you can follow these three rules, you may have a chance. -  Ed Seykota"  },
{content: "Letting losses run is the most serious mistake made by most investors. -  William O'Neil"  },     
{content: "Accepting losses is the most important single investment device to ensure the safety of capital. -  Gerald M. Loeb"  },
{content: "The key to trading success is emotional discipline. If intelligence were the key, there would be a lot more people making money trading… I know this will sound like a cliche, but the single most important reason that people lose money in the financial markets is that they don't cut their losses short. - Victor Sperandeo"  },
{content: "It's ok to be wrong; it's unforgivable to stay wrong. -  Martin Zweig"  },
{content: "In this business, if you're good, you're right six times out of ten. You're never going to be right nine times out of ten. -  Peter Lynch"  },
{content: "Every trader has strengths and weaknesses. Some are good holders of winners but may hold their losers a little too long. Others may cut their winners a little short but are quick to take their losses. As long as you stick to your own style, you get the good and bad in your own approach. -  Michael Marcus"  },      
{content: "It's not whether you're right or wrong that's important, it's how much money you make when you're right and how much you lose when you're wrong. -  George Soros"  },
{content: "Frankly, I don't see markets; I see risks, rewards, and money. -  Larry Hite"  },
{content: "Dangers of watching every tick are twofold: overtrading and increased chances of prematurely liquidating good positions. -  Jack Schwagger"  },
{content: "Don't worry about what the markets are going to do, worry about what you are going to do in response to the markets. -  Michael Carr"  },
{content: "You have to identify your weaknesses and work to change. Keep a trading diary – write down your reasons for entering and exiting every trade. Look for repetitive patterns of success and failure. -  Alexander Elder"  },
{content: "A lot of people get so enmeshed in the markets that they lose their perspective. Working longer does not necessarily equate with working smarter. In fact, sometimes is the other way around. -  Martin Schwartz"  },
{content: "A peak performance trader is totally committed to being the best and doing whatever it takes to be the best. He feels totally responsible for whatever happens and thus can learn from mistakes. These people typically have a working business plan for trading because they treat trading as a business. -  Van K. Tharp" },
{content: "You don't need to be a rocket scientist. Investing is not a game where the guy with the 160 IQ beats the guy with 130 IQ. -  Warren Buffet"  },
{content: "We simply attempt to be fearful when others are greedy and to be greedy only when others are fearful. -  Warren Buffet"  },
{content: "The market is a device for transferring money from the impatient to the patient. -  Warren Buffet"  },
{content: "Beware of trading quotes. -  Andreas Clenow"  },
{content: "An investment in knowledge pays the best interest. - Benjamin Franklin"  },
{content: "Bottoms in the investment world don't end with four-year lows; they end with 10- or 15-year lows. - Jim Rogers"  },
{content: "I will tell you how to become rich. Close the doors. Be fearful when others are greedy. Be greedy when others are fearful. - Warren Buffett"  },
{content: "With a good perspective on history, we can have a better understanding of the past and present, and thus a clear vision of the future. - Carlos Slim Helu"  },
{content: "It's not whether you're right or wrong that's important, but how much money you make when you're right and how much you lose when you're wrong. - George Soros"  },
{content: "Given a 10% chance of a 100 times payoff, you should take that bet every time. - Jeff Bezos"  },  
{content: "Don't look for the needle in the haystack. Just buy the haystack! - John Bogle"  },
{content: "I don't look to jump over seven-foot bars; I look around for one-foot bars that I can step over. - Warren Buffett"  },
{content: "The stock market is filled with individuals who know the price of everything, but the value of nothing. - Phillip Fisher"  },
{content: "In investing, what is comfortable is rarely profitable. - Robert Arnott"  },
{content: "How many millionaires do you know who have become wealthy by investing in savings accounts? I rest my case. - Robert G. Allen"  },
{content: "If there is one common theme to the vast range of the world's financial crises, it is that excessive debt accumulation, whether by the government, banks, corporations, or consumers, often poses greater systemic risks than it seems during a boom. - Carmen Reinhart"  },
{content: "We don't prognosticate macroeconomic factors, we're looking at our companies from a bottom-up perspective on their long-run prospects of returning. - Mellody Hobson"  },
{content: "Courage taught me no matter how bad a crisis gets ... any sound investment will eventually pay off. - Carlos Slim Helu"  },
{content: "The individual investor should act consistently as an investor and not as a speculator. - Ben Graham"  },
{content: "The biggest risk of all is not taking one. - Mellody Hobson"  },
{content: "Returns matter a lot. It's our capital. - Abigail Johnson"  },
{content: "It's not how much money you make, but how much money you keep, how hard it works for you, and how many generations you keep it for. - Robert Kiyosaki"  },
{content: "Know what you own, and know why you own it. - Peter Lynch"  },
{content: "Financial peace isn't the acquisition of stuff. It's learning to live on less than you make, so you can give money back and have money to invest. You can't win until you do this. - Dave Ramsey"  },
{content: "Investing should be more like watching paint dry or watching grass grow. If you want excitement, take $800 and go to Las Vegas. - Paul Samuelson"  },
{content: "The four most dangerous words in investing are, it's different this time. - Sir John Templeton"  },
{content: "Wide diversification is only required when investors do not understand what they are doing. - Warren Buffett"  },
{content: "You get recessions, you have stock market declines. If you don't understand that's going to happen, then you're not ready, you won't do well in the markets. - Peter Lynch"  },
{content: "The most contrarian thing of all is not to oppose the crowd but to think for yourself. - Peter Thiel"  },
{content: "There is no single market secret to discover, no single correct way to trade the markets. Those seeking the one true answer to the markets haven't even gotten as far as asking the right question, let alone getting the right answer. -  Jack Schwager"  },
{content: "The hard work in trading comes in the preparation. The actual process of trading, however, should be effortless. -  Jack Schwager"  },
{content: "Do more of what works and less of what doesn't. - Steve Clark"  },
{content: "My attitude is that I always want to be better prepared than someone I'm competing against. The way I prepare myself is by doing my work each night. - Marty Schwartz"  },
{content: "I just wait until there is money lying in the corner, and all I have to do is go over there and pick it up. I do nothing in the meantime. - Jim Rogers"  },
{content: "The market can stay irrational longer than you can stay solvent. - John Maynard Keynes"  },       
{content: "If most traders would learn to sit on their hands 50 percent of the time, they would make a lot more money. - Bill Lipschutz"  },
{content: "Don't worry about what the markets are going to do, worry about what you are going to do in response to the markets. - Michael Carr"  },
{content: "It's not whether you're right or wrong that's important, it's how much money you make when you're right and how much you lose when you're wrong. - George Soros"  },
{content: "Win or lose, everybody gets what they want from the market. Some people seem to like to lose, so they win by losing money. - Ed Seykota"  },
{content: "Where you want to be is always in control, never wishing, always trading, and always, first and foremost protecting your butt. After a while size means nothing. It gets back to whether you're making 100% rate of return on $10,000 or $100 million dollars. It doesn't make any difference. - Paul Tudor Jones"  },      
{content: "Don't focus on making money, focus on protecting what you have. - Paul Tudor Jones"  },
{content: "I know where I'm getting out before I get in. - Bruce Kovner"  },
{content: "When in doubt, get out and get a good night's sleep. I've done that lots of times and the next day everything was clear… While you are in [the position], you can't think. When you get out, then you can think clearly again. - Michael Marcus"  },
{content: "There is the plain fool, who does the wrong thing at all times everywhere, but there is the Wall Street fool, who thinks he must trade all the time. - Jesse Livermore"  },
{content: "To compound your money, and not your mistakes, your goal is to buy on the way up—not on the way down. -  Mark Minervini"  },
{content: "It's better to lose correctly than to win incorrectly. -  Mark Minervini"  },
{content: "Virtually every superperformance phase in big, winning stocks occurred while the stock price was in a definite price uptrend. In almost every case, the trend was identifiable early in the superperformance advance. -  Mark Minervini,"  },
{content: "You want to get on board when institutional money is pouring into a stock and lifting it significantly higher. -  Mark Minervini,"  },
{content: "If you find yourself getting stopped out of your positions over and over, there can only be two things wrong: 1. Your stock selection criteria are flawed. 2. The general market environment is hostile. -  Mark Minervini, "  },
{content: "If you don't have a plan, you will surely experience paralyzing emotions and second-guess yourself at key decision-making moments. -  Mark Minervini"  },
{content: "Expectancy is your percentage of winning trades multiplied by your average gain, divided by your percentage of losing trades multiplied by your average loss. Maintain a positive expectancy, and you're a winner. My results went from average to stellar when I finally made the choice that I was going to make every trade an intelligent risk/reward decision. -  Mark Minervini"  },
{content: "When you sell half, if the stock goes higher you say to yourself, Thank goodness I kept half. If the stock goes lower, you'll say, Thank goodness I sold half. Psychologically, it's a win/win either way. -  Mark Minervini"  },
{content: "The birch canoe slid on the smooth planks. "},
{content: "Glue the sheet to the dark blue background."},
{content: "It's easy to tell the depth of a well. "},
{content: "These days a chicken leg is a rare dish. "},
{content: "Rice is often served in round bowls."},
{content: "The juice of lemons makes fine punch. "},
{content: "The box was thrown beside the parked truck. "},
{content: "The hogs were fed chopped corn and garbage. "},
{content: "Four hours of steady work faced us. "},
{content: "A large size in stockings is hard to sell. "},
{content: "The boy was there when the sun rose."},
{content: "A rod is used to catch pink salmon. "},
{content: "The source of the huge river is the clear spring. "},
{content: "Kick the ball straight and follow through."},
{content: "Help the woman get back to her feet. "},
{content: "A pot of tea helps to pass the evening. "},
{content: "Smoky fires lack flame and heat. "},
{content: "The soft cushion broke the man's fall. "},
{content: "The salt breeze came across from the sea. "},
{content: "The girl at the booth sold fifty bonds. "},
{content: "The small pup gnawed a hole in the sock. "},
{content: "The fish twisted and turned on the bent hook. "},
{content: "Press the pants and sew a button on the vest. "},
{content: "The swan dive was far short of perfect. "},
{content: "The beauty of the view stunned the young boy. "},
{content: "Two blue fish swam in the tank. "},
{content: "Her purse was full of useless trash. "},
{content: "The colt reared and threw the tall rider. "},
{content: "It snowed, rained, and hailed the same morning. "},
{content: "Read verse out loud for pleasure."},
{content: "Hoist the load to your left shoulder. "},
{content: "Take the winding path to reach the lake. "},
{content: "Note closely the size of the gas tank. "},
{content: "Wipe the grease off his dirty face. "},
{content: "Mend the coat before you go out. "},
{content: "The wrist was badly strained and hung limp. "},
{content: "The stray cat gave birth to kittens. "},
{content: "The young girl gave no clear response. "},
{content: "The meal was cooked before the bell rang."},
{content: "What joy there is in living. "},
{content: "A king ruled the state in the early days. "},
{content: "The ship was torn apart on the sharp reef. "},
{content: "Sickness kept him home the third week. "},
{content: "The wide road shimmered in the hot sun. "},
{content: "The lazy cow lay in the cool grass. "},
{content: "Lift the square stone over the fence. "},
{content: "The rope will bind the seven books at once. "},
{content: "Hop over the fence and plunge in. "},
{content: "The friendly gang left the drug store. "},
{content: "Mesh wire keeps chicks inside. "},
{content: "The frosty air passed through the coat. "},
{content: "The crooked maze failed to fool the mouse. "},
{content: "Adding fast leads to wrong sums. "},
{content: "The show was a flop from the very start. "},
{content: "A saw is a tool used for making boards."},
{content: "The wagon moved on well oiled wheels."},
{content: "March the soldiers past the next hill. "},
{content: "A cup of sugar makes sweet fudge. "},
{content: "Place a rosebush near the porch steps. "},
{content: "Both lost their lives in the raging storm. "},
{content: "We talked of the side show in the circus."},
{content: "Use a pencil to write the first draft."},
{content: "He ran half way to the hardware store. "},
{content: "The clock struck to mark the third period."},
{content: "A small creek cut across the field."},
{content: "Cars and busses stalled in snow drifts."},
{content: "The set of china hit the floor with a crash. "},
{content: "This is a grand season for hikes on the road."},
{content: "The dune rose from the edge of the water. "},
{content: "Those words were the cue for the actor to leave."},
{content: "A yacht slid around the point into the bay."},
{content: "The two met while playing on the sand."},
{content: "The ink stain dried on the finished page."},
{content: "The walled town was seized without a fight. "},
{content: "The lease ran out in sixteen weeks. "},
{content: "A tame squirrel makes a nice pet. "},
{content: "The horn of the car woke the sleeping cop. "},
{content: "The heart beat strongly and with firm strokes. "},
{content: "The pearl was worn in a thin silver ring. "},
{content: "The fruit peel was cut in thick slices."},
{content: "The Navy attacked the big task force."},
{content: "See the cat glaring at the scared mouse. "},
{content: "There are more than two factors here."},
{content: "The hat brim was wide and too droopy."},
{content: "The lawyer tried to lose his case."},
{content: "The grass curled around the fence post."},
{content: "Cut the pie into large parts."},
{content: "Men strive but seldom get rich. "},
{content: "Always close the barn door tight."},
{content: "He lay prone and hardly moved a limb."},
{content: "The slush lay deep along the street. "},
{content: "A wisp of cloud hung in the blue air. "},
{content: "A pound of sugar costs more than eggs."},
{content: "The fin was sharp and cut the clear water."},
{content: "The play seems dull and quite stupid."},
{content: "Bail the boat to stop it from sinking."},
{content: "The term ended in late June that year."},
{content: "A Tusk is used to make costly gifts."},
{content: "Ten pins were set in order."},
{content: "The bill was paid every third week."},
{content: "Oak is strong and also gives shade."},
{content: "Cats and Dogs each hate the other."},
{content: "The pipe began to rust while new."},
{content: "Open the crate but don't break the glass."},
{content: "Add the sum to the product of these three."},
{content: "Thieves who rob friends deserve jail."},
{content: "The ripe taste of cheese improves with age."},
{content: "Act on these orders with great speed."},
{content: "The hog crawled under the high fence."},
{content: "Move the vat over the hot fire."},
{content: "The bark of the pine tree was shiny and dark."},
{content: "Leaves turn brown and yellow in the fall. "},
{content: "The pennant waved when the wind blew."},
{content: "Split the log with a quick, sharp blow."},
{content: "Burn peat after the logs give out."},
{content: "He ordered peach pie with ice cream."},
{content: "Weave the carpet on the right hand side."},
{content: "Hemp is a weed found in parts of the tropics."},
{content: "A lame back kept his score low."},
{content: "We find joy in the simplest things."},
{content: "Type out three lists of orders."},
{content: "The harder he tried the less he got done."},
{content: "The boss ran the show with a watchful eye."},
{content: "The cup cracked and spilled its contents."},
{content: "Paste can cleanse the most dirty brass."},
{content: "The slang word for raw whiskey is booze."},
{content: "It caught its hind paw in a rusty trap."},
{content: "The wharf could be seen at the farther shore."},
{content: "Feel the heat of the weak dying flame."},
{content: "The tiny girl took off her hat."},
{content: "A cramp is no small danger on a swim."},
{content: "He said the same phrase thirty times."},
{content: "Pluck the bright rose without leaves."},
{content: "Two plus seven is less than ten."},
{content: "The glow deepened in the eyes of the sweet girl."},
{content: "Bring your problems to the wise chief."},
{content: "Write a fond note to the friend you cherish."},
{content: "Clothes and lodging are free to new men."},
{content: "We frown when events take a bad turn."},
{content: "Port is a strong wine with a smoky taste."},
{content: "The young kid jumped the rusty gate."},
{content: "Guess the result from the first scores. "},
{content: "A salt pickle tastes fine with ham."},
{content: "The just claim got the right verdict."},
{content: "Those thistles bend in a high wind."},
{content: "Pure bred poodles have curls."},
{content: "The tree top waved in a graceful way. "},
{content: "The spot on the blotter was made by green ink. "},
{content: "Mud was spattered on the front of his white shirt."},
{content: "The cigar burned a hole in the desk top. "},
{content: "The empty flask stood on the tin tray."},
{content: "A speedy man can beat this track mark. "},
{content: "He broke a new shoelace that day."},
{content: "The coffee stand is too high for the couch. "},
{content: "The urge to write short stories is rare. "},
{content: "The pencils have all been used."},
{content: "The pirates seized the crew of the lost ship."},
{content: "We tried to replace the coin but failed. "},
{content: "She sewed the torn coat quite neatly. "},
{content: "The sofa cushion is red and of light weight."},
{content: "The jacket hung on the back of the wide chair."},
{content: "At that high level the air is pure."},
{content: "Drop the two when you add the figures."},
{content: "A filing case is now hard to buy."},
{content: "An abrupt start does not win the prize. "},
{content: "Wood is best for making toys and blocks."},
{content: "The office paint was a dull, sad tan."},
{content: "He knew the skill of the great young actress."},
{content: "A rag will soak up spilled water."},
{content: "A shower of dirt fell from the hot pipes."},
{content: "Steam hissed from the broken valve. "},
{content: "The child almost hurt the small dog."},
{content: "There was a sound of dry leaves outside."},
{content: "The sky that morning was clear and bright blue."},
{content: "Torn scraps littered the stone floor."},
{content: "Sunday is the best part of the week. "},
{content: "The doctor cured him with these pills."},
{content: "The new girl was fired today at noon."},
{content: "They felt gay when the ship arrived in port."},
{content: "Add the store's account to the last cent."},
{content: "Acid burns holes in wool cloth."},
{content: "Fairy tales should be fun to write."},
{content: "Eight miles of woodland burned to waste."},
{content: "The third act was dull and tired the players."},
{content: "A young child should not suffer fright."},
{content: "Add the column and put the sum here."},
{content: "We admire and love a good cook."},
{content: "There the flood mark is ten inches."},
{content: "He carved a head from the round block of marble."},
{content: "She has a smart way of wearing clothes."},
{content: "The fruit of a fig tree is apple shaped."},
{content: "Corn cobs can be used to kindle a fire. "},
{content: "Where were they when the noise started."},
{content: "The paper box is full of thumb tacks."},
{content: "Sell your gift to a buyer at a good gain."},
{content: "The tongs lay beside the ice pail."},
{content: "The petals fall with the next puff of wind."},
{content: "Bring your best compass to the third class."},
{content: "They could laugh although they were sad."},
{content: "Farmers came in to thresh the oat crop."},
{content: "The brown house was on fire to the attic."},
{content: "The lure is used to catch trout and flounder."},
{content: "Float the soap on top of the bath water."},
{content: "A blue crane is a tall wading bird."},
{content: "A fresh start will work such wonders."},
{content: "The club rented the rink for the fifth night."},
{content: "After the dance, they went straight home."},
{content: "The hostess taught the new maid to serve."},
{content: "He wrote his last novel there at the inn."},
{content: "Even the worst will beat his low score."},
{content: "The cement had dried when he moved it."},
{content: "The loss of the second ship was hard to take."},
{content: "The fly made its way along the wall."},
{content: "Do that with a wooden stick."},
{content: "Live wires should be kept covered."},
{content: "The large house had hot water taps."},
{content: "It is hard to erase blue or red ink."},
{content: "Write at once or you may forget it."},
{content: "The doorknob was made of bright clean brass."},
{content: "The wreck occurred by the bank on Main Street."},
{content: "A pencil with black lead writes best."},
{content: "Coax a young calf to drink from a bucket."},
{content: "Schools for ladies teach charm and grace."},
{content: "The lamp shone with a steady green flame."},
{content: "They took the axe and the saw to the forest."},
{content: "The ancient coin was quite dull and worn."},
{content: "The shaky barn fell with a loud crash. "},
{content: "Jazz and swing fans like fast music."},
{content: "Rake the rubbish up and then burn it."},
{content: "Slash the gold cloth into fine ribbons."},
{content: "Try to have the court decide the case."},
{content: "They are pushed back each time they attack."},
{content: "He broke his ties with groups of former friends."},
{content: "They floated on the raft to sun their white backs."},
{content: "The map had an X that meant nothing."},
{content: "Whitings are small fish caught in nets."},
{content: "Some ads serve to cheat buyers."},
{content: "Jerk the rope and the bell rings weakly."},
{content: "A waxed floor makes us lose balance."},
{content: "Madam, this is the best brand of corn."},
{content: "On the islands the sea breeze is soft and mild."},
{content: "The play began as soon as we sat down."},
{content: "This will lead the world to more sound and fury."},
{content: "Add salt before you fry the egg."},
{content: "The rush for funds reached its peak Tuesday."},
{content: "The birch looked stark white and lonesome."},
{content: "The box is held by a bright red snapper."},
{content: "To make pure ice, you freeze water."},
{content: "The first worm gets snapped early."},
{content: "Jump the fence and hurry up the bank."},
{content: "Yell and clap as the curtain slides back."},
{content: "They are men who walk the middle of the road."},
{content: "Both brothers wear the same size."},
{content: "In some form or other we need fun."},
{content: "The prince ordered his head chopped off."},
{content: "The houses are built of red clay bricks."},
{content: "Ducks fly north but lack a compass."},
{content: "Fruit flavors are used in fizz drinks."},
{content: "These pills do less good than others."},
{content: "Canned pears lack full flavor."},
{content: "The dark pot hung in the front closet."},
{content: "Carry the pail to the wall and spill it there."},
{content: "The train brought our hero to the big town."},
{content: "We are sure that one war is enough."},
{content: "Gray paint stretched for miles around."},
{content: "The rude laugh filled the empty room."},
{content: "High seats are best for football fans."},
{content: "Tea served from the brown jug is tasty."},
{content: "A dash of pepper spoils beef stew."},
{content: "A zestful food is the hot-cross bun."},
{content: "The horse trotted around the field at a brisk pace."},
{content: "Find the twin who stole the pearl necklace."},
{content: "Cut the cord that binds the box tightly."},
{content: "The red tape bound the smuggled food."},
{content: "Look in the corner to find the tan shirt."},
{content: "The cold drizzle will halt the bond drive."},
{content: "Nine men were hired to dig the ruins."},
{content: "The junk yard had a mouldy smell."},
{content: "The flint sputtered and lit a pine torch."},
{content: "Soak the cloth and drown the sharp odor."},
{content: "The shelves were bare of both jam or crackers."},
{content: "A joy to every child is the swan boat."},
{content: "All sat frozen and watched the screen."},
{content: "A cloud of dust stung his tender eyes."},
{content: "To reach the end he needs much courage."},
{content: "Shape the clay gently into block form."},
{content: "A ridge on a smooth surface is a bump or flaw."},
{content: "Hedge apples may stain your hands green."},
{content: "Quench your thirst, then eat the crackers."},
{content: "Tight curls get limp on rainy days."},
{content: "The mute muffled the high tones of the horn."},
{content: "The gold ring fits only a pierced ear."},
{content: "The old pan was covered with hard fudge."},
{content: "Watch the log float in the wide river."},
{content: "The node on the stalk of wheat grew daily."},
{content: "The heap of fallen leaves was set on fire."},
{content: "Write fast if you want to finish early."},
{content: "His shirt was clean but one button was gone."},
{content: "The barrel of beer was a brew of malt and hops."},
{content: "Tin cans are absent from store shelves."},
{content: "Slide the box into that empty space."},
{content: "The plant grew large and green in the window."},
{content: "The beam dropped down on the workman's head."},
{content: "Pink clouds floated with the breeze."},
{content: "She danced like a swan, tall and graceful."},
{content: "The tube was blown and the tire flat and useless."},
{content: "It is late morning on the old wall clock."},
{content: "Let's all join as we sing the last chorus."},
{content: "The last switch cannot be turned off."},
{content: "The fight will end in just six minutes."},
{content: "The store walls were lined with colored frocks."},
{content: "The peace league met to discuss their plans. "},
{content: "The rise to fame of a person takes luck."},
{content: "Paper is scarce, so write with much care."},
{content: "The quick fox jumped on the sleeping cat."},
{content: "The nozzle of the fire hose was bright brass."},
{content: "Screw the round cap on as tight as needed."},
{content: "Time brings us many changes."},
{content: "The purple tie was ten years old."},
{content: "Men think and plan and sometimes act."},
{content: "Fill the ink jar with sticky glue."},
{content: "He smoke a big pipe with strong contents."},
{content: "We need grain to keep our mules healthy."},
{content: "Pack the records in a neat thin case."},
{content: "The crunch of feet in the snow was the only sound."},
{content: "The copper bowl shone in the sun's rays."},
{content: "Boards will warp unless kept dry. "},
{content: "The plush chair leaned against the wall. "},
{content: "Glass will clink when struck by metal."},
{content: "Bathe and relax in the cool green grass."},
{content: "Nine rows of soldiers stood in a line."},
{content: "The beach is dry and shallow at low tide."},
{content: "The idea is to sew both edges straight."},
{content: "The kitten chased the dog down the street."},
{content: "Pages bound in cloth make a book."},
{content: "Try to trace the fine lines of the painting."},
{content: "Women form less than half of the group. "},
{content: "The zones merge in the central part of town."},
{content: "A gem in the rough needs work to polish."},
{content: "Code is used when secrets are sent."},
{content: "Most of the news is easy for us to hear."},
{content: "He used the lathe to make brass objects."},
{content: "The vane on top of the pole revolved in the wind."},
{content: "Mince pie is a dish served to children."},
{content: "The clan gathered on each dull night."},
{content: "Let it burn, it gives us warmth and comfort."},
{content: "A castle built from sand fails to endure."},
{content: "A child's wit saved the day for us."},
{content: "Tack the strip of carpet to the worn floor."},
{content: "Next Tuesday we must vote."},
{content: "Pour the stew from the pot into the plate."},
{content: "Each penny shone like new."},
{content: "The man went to the woods to gather sticks."},
{content: "The dirt piles were lines along the road."},
{content: "The logs fell and tumbled into the clear stream."},
{content: "Just hoist it up and take it away."},
{content: "A ripe plum is fit for a king's palate."},
{content: "Our plans right now are hazy. "},
{content: "Brass rings are sold by these natives."},
{content: "It takes a good trap to capture a bear."},
{content: "Feed the white mouse some flower seeds."},
{content: "The thaw came early and freed the stream."},
{content: "He took the lead and kept it the whole distance."},
{content: "The key you designed will fit the lock."},
{content: "Plead to the council to free the poor thief."},
{content: "Better hash is made of rare beef."},
{content: "This plank was made for walking on ."},
{content: "The lake sparkled in the red hot sun."},
{content: "He crawled with care along the ledge."},
{content: "Tend the sheep while the dog wanders."},
{content: "It takes a lot of help to finish these."},
{content: "Mark the spot with a sign painted red."},
{content: "Take two shares as a fair profit. "},
{content: "The fur of cats goes by many names."},
{content: "North winds bring colds and fevers."},
{content: "He asks no person to vouch for him."},
{content: "Go now and come here later."},
{content: "A sash of gold silk will trim her dress."},
{content: "Soap can wash most dirt away."},
{content: "That move means the game is over."},
{content: "He wrote down a long list of items."},
{content: "A siege will crack the strong defense."},
{content: "Grape juice and water mix well."},
{content: "Roads are paved with sticky tar."},
{content: "Fake stones shine but cost little."},
{content: "The drip of the rain made a pleasant sound. "},
{content: "Smoke poured out of every crack. "},
{content: "Serve the hot rum to the tired heroes."},
{content: "Much of the story makes good sense. "},
{content: "The sun came up to light the eastern sky."},
{content: "Heave the line over the port side."},
{content: "A lathe cuts and trims any wood."},
{content: "It's a dense crowd in two distinct ways."},
{content: "His hip struck the knee of the next player."},
{content: "The stale smell of old beer lingers."},
{content: "The desk was firm on the shaky floor."},
{content: "It takes heat to bring out the odor."},
{content: "Beef is scarcer than some lamb."},
{content: "Raise the sail and steer the ship northward."},
{content: "A cone costs five cents on Mondays."},
{content: "A pod is what peas always grow in."},
{content: "Jerk that dart from the cork target."},
{content: "No cement will hold hard wood."},
{content: "We now have a new base for shipping."},
{content: "A list of names is carved around the base."},
{content: "The sheep were led home by a dog."},
{content: "Three for a dime, the young peddler cried."},
{content: "The sense of smell is better than that of touch."},
{content: "No hardship seemed to make him sad."},
{content: "Grace makes up for lack of beauty."},
{content: "Nudge gently but wake her now."},
{content: "The news struck doubt into restless minds."},
{content: "Once we stood beside the shore."},
{content: "A chink in the wall allowed a draft to blow."},
{content: "Fasten two pins on each side."},
{content: "A cold dip restores health and zest."},
{content: "He takes the oath of office each March."},
{content: "The sand drifts over the sills of the old house."},
{content: "The point of the steel pen was bent and twisted."},
{content: "There is a lag between thought and act."},
{content: "Seed is needed to plant the spring corn."},
{content: "Draw the chart with heavy black lines."},
{content: "The boy owed his pal thirty cents."},
{content: "The chap slipped into the crowd and was lost."},
{content: "Hats are worn to tea and not to dinner."},
{content: "The ramp led up to the wide highway."},
{content: "Beat the dust from the rug onto the lawn."},
{content: "Say it slowly but make it ring clear."},
{content: "The straw nest housed five robins."},
{content: "Screen the porch with woven straw mats."},
{content: "This horse will nose his way to the finish."},
{content: "The dry wax protects the deep scratch."},
{content: "He picked up the dice for a second roll."},
{content: "These coins will be needed to pay his debt."},
{content: "The nag pulled the frail cart along."},
{content: "Twist the valve and release hot steam."},
{content: "The vamp of the shoe had a gold buckle."},
{content: "The smell of burned rags itches my nose."},
{content: "New pants lack cuffs and pockets."},
{content: "The marsh will freeze when cold enough."},
{content: "They slice the sausage thin with a knife."},
{content: "The bloom of the rose lasts a few days."},
{content: "A gray mare walked before the colt."},
{content: "Breakfast buns are fine with a hot drink."},
{content: "Bottles hold four kinds of rum."},
{content: "The man wore a feather in his felt hat."},
{content: "He wheeled the bike past the winding road."},
{content: "Drop the ashes on the worn old rug."},
{content: "The desk and both chairs were painted tan."},
{content: "Throw out the used paper cup and plate."},
{content: "A clean neck means a neat collar."},
{content: "The couch cover and hall drapes were blue."},
{content: "The stems of the tall glasses cracked and broke."},
{content: "The wall phone rang loud and often."},
{content: "The clothes dried on a thin wooden rack."},
{content: "Turn out the lantern which gives us light."},
{content: "The cleat sank deeply into the soft turf."},
{content: "The bills were mailed promptly on the tenth of the month."},
{content: "To have is better than to wait and hope."},
{content: "The price is fair for a good antique clock."},
{content: "The music played on while they talked."},
{content: "Dispense with a vest on a day like this."},
{content: "The bunch of grapes was pressed into wine."},
{content: "He sent the figs, but kept the ripe cherries."},
{content: "The hinge on the door creaked with old age."},
{content: "The screen before the fire kept in the sparks."},
{content: "Fly by night and you waste little time."},
{content: "Thick glasses helped him read the print."},
{content: "Birth and death marks the limits of life."},
{content: "The chair looked strong but had no bottom."},
{content: "The kite flew wildly in the high wind."},
{content: "A fur muff is stylish once more."},
{content: "The tin box held priceless stones."},
{content: "We need an end of all such matter."},
{content: "The case was puzzling to the old and wise."},
{content: "The bright lanterns were gay on the dark lawn."},
{content: "We don't get much money but we have fun."},
{content: "The youth drove with zest, but little skill."},
{content: "Five years he lived with a shaggy dog."},
{content: "A fence cuts through the corner lot."},
{content: "The way to save money is not to spend much."},
{content: "Shut the hatch before the waves push it in."},
{content: "The odor of spring makes young hearts jump."},
{content: "Crack the walnut with your sharp side teeth."},
{content: "He offered proof in the form of a large chart."},
{content: "Send the stuff in a thick paper bag."},
{content: "A quart of milk is water for the most part."},
{content: "They told wild tales to frighten him."},
{content: "The three story house was built of stone."},
{content: "In the rear of the ground floor was a large passage."},
{content: "A man in a blue sweater sat at the desk."},
{content: "Oats are a food eaten by horse and man."},
{content: "Their eyelids droop for want of sleep."},
{content: "A sip of tea revives his tired friend."},
{content: "There are many ways to do these things."},
{content: "Tuck the sheet under the edge of the mat."},
{content: "A force equal to that would move the earth."},
{content: "We like to see clear weather."},
{content: "The work of the tailor is seen on each side."},
{content: "Take a chance and win a china doll."},
{content: "Shake the dust from your shoes, stranger."},
{content: "She was kind to sick old people."},
{content: "The square wooden crate was packed to be shipped."},
{content: "The dusty bench stood by the stone wall."},
{content: "We dress to suit the weather of most days."},
{content: "Smile when you say nasty words."},
{content: "A bowl of rice is free with chicken stew."},
{content: "The water in this well is a source of good health."},
{content: "Take shelter in this tent, but keep still."},
{content: "That guy is the writer of a few banned books."},
{content: "The little tales they tell are false."},
{content: "The door was barred, locked, and bolted as well."},
{content: "Ripe pears are fit for a queen's table."},
{content: "A big wet stain was on the round carpet."},
{content: "The kite dipped and swayed, but stayed aloft."},
{content: "The pleasant hours fly by much too soon."},
{content: "The room was crowded with a wild mob."},
{content: "This strong arm shall shield your honor."},
{content: "She blushed when he gave her a white orchid."},
{content: "The beetle droned in the hot June sun."},
{content: "Press the pedal with your left foot."},
{content: "Neat plans fail without luck."},
{content: "The black trunk fell from the landing."},
{content: "The bank pressed for payment of the debt."},
{content: "The theft of the pearl pin was kept secret."},
{content: "Shake hands with this friendly child."},
{content: "The vast space stretched into the far distance."},
{content: "A rich farm is rare in this sandy waste."},
{content: "His wide grin earned many friends."},
{content: "Flax makes a fine brand of paper."},
{content: "Hurdle the pit with the aid of a long pole."},
{content: "A strong bid may scare your partner stiff."},
{content: "Even a just cause needs power to win."},
{content: "Peep under the tent and see the clowns."},
{content: "The leaf drifts along with a slow spin."},
{content: "Cheap clothes are flashy but don't last."},
{content: "A thing of small note can cause despair."},
{content: "Flood the mails with requests for this book."},
{content: "A thick coat of black paint covered all."},
{content: "The pencil was cut to be sharp at both ends."},
{content: "Those last words were a strong statement."},
{content: "He wrote his name boldly at the top of the sheet."},
{content: "Dill pickles are sour but taste fine."},
{content: "Down that road is the way to the grain farmer."},
{content: "Either mud or dust are found at all times."},
{content: "The best method is to fix it in place with clips."},
{content: "If you mumble your speech will be lost."},
{content: "At night the alarm roused him from a deep sleep."},
{content: "Read just what the meter says."},
{content: "Fill your pack with bright trinkets for the poor."},
{content: "The small red neon lamp went out."},
{content: "Clams are small, round, soft, and tasty."},
{content: "The fan whirled its round blades softly."},
{content: "The line where the edges join was clean."},
{content: "Breathe deep and smell the piny air."},
{content: "It matters not if he reads these words or those."},
{content: "A brown leather bag hung from its strap."},
{content: "A toad and a frog are hard to tell apart."},
{content: "A white silk jacket goes with any shoes."},
{content: "A break in the dam almost caused a flood."},
{content: "Paint the sockets in the wall dull green."},
{content: "The child crawled into the dense grass."},
{content: "Bribes fail where honest men work."},
{content: "Trample the spark, else the flames will spread."},
{content: "The hilt of the sword was carved with fine designs."},
{content: "A round hole was drilled through the thin board."},
{content: "Footprints showed the path he took up the beach."},
{content: "She was waiting at my front lawn."},
{content: "A vent near the edge brought in fresh air."},
{content: "Prod the old mule with a crooked stick."},
{content: "It is a band of steel three inches wide."},
{content: "The pipe ran almost the length of the ditch."},
{content: "It was hidden from sight by a mass of leaves and shrubs."},
{content: "The weight of the package was seen on the high scale."},
{content: "Wake and rise, and step into the green outdoors."},
{content: "The green light in the brown box flickered."},
{content: "The brass tube circled the high wall."},
{content: "The lobes of her ears were pierced to hold rings."},
{content: "Hold the hammer near the end to drive the nail."},
{content: "Next Sunday is the twelfth of the month."},
{content: "Every word and phrase he speaks is true."},
{content: "He put his last cartridge into the gun and fired."},
{content: "They took their kids from the public school."},
{content: "Drive the screw straight into the wood."},
{content: "Keep the hatch tight and the watch constant."},
{content: "Sever the twine with a quick snip of the knife."},
{content: "Paper will dry out when wet."},
{content: "Slide the catch back and open the desk."},
{content: "Help the weak to preserve their strength."},
{content: "A sullen smile gets few friends."},
{content: "Stop whistling and watch the boys march."},
{content: "Jerk the cord, and out tumbles the gold."},
{content: "Slide the tray across the glass top."},
{content: "The cloud moved in a stately way and was gone."},
{content: "Light maple makes for a swell room."},
{content: "Set the piece here and say nothing."},
{content: "Dull stories make her laugh."},
{content: "A stiff cord will do to fasten your shoe."},
{content: "Get the trust fund to the bank early."},
{content: "Choose between the high road and the low."},
{content: "A plea for funds seems to come again."},
{content: "He lent his coat to the tall gaunt stranger."},
{content: "There is a strong chance it will happen once more."},
{content: "The duke left the park in a silver coach."},
{content: "Greet the new guests and leave quickly."},
{content: "When the frost has come it is time for turkey."},
{content: "Sweet words work better than fierce."},
{content: "A thin stripe runs down the middle."},
{content: "A six comes up more often than a ten."},
{content: "Lush ferns grow on the lofty rocks."},
{content: "The ram scared the school children off."},
{content: "The team with the best timing looks good."},
{content: "The farmer swapped his horse for a brown ox."},
{content: "Sit on the perch and tell the others what to do."},
{content: "A steep trail is painful for our feet."},
{content: "The early phase of life moves fast."},
{content: "Green moss grows on the northern side."},
{content: "Tea in thin china has a sweet taste."},
{content: "Pitch the straw through the door of the stable."},
{content: "The latch on the back gate needed a nail."},
{content: "The goose was brought straight from the old market."},
{content: "The sink is the thing in which we pile dishes."},
{content: "A whiff of it will cure the most stubborn cold."},
{content: "The facts don't always show who is right."},
{content: "She flaps her cape as she parades the street."},
{content: "The loss of the cruiser was a blow to the fleet."},
{content: "Loop the braid to the left and then over."},
{content: "Plead with the lawyer to drop the lost cause."},
{content: "Calves thrive on tender spring grass."},
{content: "Post no bills on this office wall."},
{content: "Tear a thin sheet from the yellow pad."},
{content: "A cruise in warm waters in a sleek yacht is fun."},
{content: "A streak of color ran down the left edge."},
{content: "It was done before the boy could see it."},
{content: "Crouch before you jump or miss the mark."},
{content: "Pack the kits and don't forget the salt."},
{content: "The square peg will settle in the round hole."},
{content: "Fine soap saves tender skin."},
{content: "Poached eggs and tea must suffice."},
{content: "Bad nerves are jangled by a door slam."},
{content: "Ship maps are different from those for planes."},
{content: "Dimes showered down from all sides."},
{content: "They sang the same tunes at each party."},
{content: "The sky in the west is tinged with orange red."},
{content: "The pods of peas ferment in bare fields."},
{content: "The horse balked and threw the tall rider."},
{content: "The hitch between the horse and cart broke."},
{content: "Pile the coal high in the shed corner."},
{content: "A gold vase is both rare and costly."},
{content: "The knife was hung inside its bright sheath."},
{content: "The rarest spice comes from the far East."},
{content: "The roof should be tilted at a sharp slant."},
{content: "A smatter of French is worse than none."},
{content: "The mule trod the treadmill day and night."},
{content: "The aim of the contest is to raise a great fund."},
{content: "To send it now in large amounts is bad."},
{content: "There is a fine hard tang in salty air."},
{content: "Cod is the main business of the north shore."},
{content: "The slab was hewn from heavy blocks of slate."},
{content: "Dunk the stale biscuits into strong drink."},
{content: "Hang tinsel from both branches."},
{content: "Cap the jar with a tight brass cover."},
{content: "The poor boy missed the boat again."},
{content: "Be sure to set that lamp firmly in the hole."},
{content: "Pick a card and slip it under the pack."},
{content: "A round mat will cover the dull spot."},
{content: "The first part of the plan needs changing."},
{content: "A good book informs of what we ought to know."},
{content: "The mail comes in three batches per day."},
{content: "You cannot brew tea in a cold pot."},
{content: "Dots of light betrayed the black cat."},
{content: "Put the chart on the mantel and tack it down."},
{content: "The night shift men rate extra pay."},
{content: "The red paper brightened the dim stage."},
{content: "See the player scoot to third base."},
{content: "Slide the bill between the two leaves."},
{content: "Many hands help get the job done."},
{content: "We don't like to admit our small faults."},
{content: "No doubt about the way the wind blows."},
{content: "Dig deep in the earth for pirate's gold."},
{content: "The steady drip is worse than a drenching rain."},
{content: "A flat pack takes less luggage space."},
{content: "Green ice frosted the punch bowl."},
{content: "A stuffed chair slipped from the moving van."},
{content: "The stitch will serve but needs to be shortened."},
{content: "A thin book fits in the side pocket."},
{content: "The gloss on top made it unfit to read."},
{content: "The hail pattered on the burnt brown grass."},
{content: "Seven seals were stamped on great sheets."},
{content: "Our troops are set to strike heavy blows."},
{content: "The store was jammed before the sale could start."},
{content: "It was a bad error on the part of the new judge."},
{content: "One step more and the board will collapse."},
{content: "Take the match and strike it against your shoe."},
{content: "The pot boiled but the contents failed to jell."},
{content: "The baby puts his right foot in his mouth."},
{content: "The bombs left most of the town in ruins."},
{content: "Stop and stare at the hard working man."},
{content: "The streets are narrow and full of sharp turns."},
{content: "The pup jerked the leash as he saw a feline shape."},
{content: "Open your book to the first page."},
{content: "Fish evade the net and swim off."},
{content: "Dip the pail once and let it settle."},
{content: "Will you please answer that phone."},
{content: "The big red apple fell to the ground."},
{content: "The curtain rose and the show was on."},
{content: "The young prince became heir to the throne."},
{content: "He sent the boy on a short errand."},
{content: "Leave now and you will arrive on time."},
{content: "The corner store was robbed last night."},
{content: "A gold ring will please most any girl."},
{content: "The long journey home took a year."},
{content: "She saw a cat in the neighbor's house."},
{content: "A pink shell was found on the sandy beach."},
{content: "Small children came to see him."},
{content: "The grass and bushes were wet with dew."},
{content: "The blind man counted his old coins."},
{content: "A severe storm tore down the barn."},
{content: "She called his name many times."},
{content: "When you hear the bell, come quickly."}
];

const RandomQuote = () => {
  let r = Math.floor(Math.random() * 100);
  let randData = hsData[r];
  let randSentence = randData.content;
  

  return (
   
        <div className="quote">{randSentence}</div>
    );
};
export default RandomQuote;
